<template>
  <div v-if="data">
    <block-title>品項: </block-title>
    <store-order-card
      v-for="(item, index) in orderCardData"
      :key="index"
      :index="index"
      v-model="orderCardData[index]"
      :configs="cardConfigs(index)"
    ></store-order-card>
    <v-row class="mb-5">
      <v-col cols="6" class="py-0 pr-0">
        <number-bar
          title="運費"
          unit="元"
          v-model="delivery_fee"
          readonly
          name="delivery_fee"
          :type="'mine'"
          class="border-0 store-bg-color white--text rounded-r-0"
          :fixed="0"
        ></number-bar>
      </v-col>
      <v-col cols="6" class="py-0 pl-0">
        <number-bar
          v-if="amount"
          :title="itemNumberBarTitle"
          unit="元"
          reload
          @reload="handleResetTotal"
          v-model="amount"
          readonly
          name="amount"
          :type="'mine'"
          class="border-0 store-bg-color white--text rounded-l-0"
          :fixed="0"
        ></number-bar>
      </v-col>
    </v-row>

    <v-textarea
      v-show="comment && comment.length > 0"
      v-model="comment"
      label="備註"
      outlined
      name="comment"
      auto-grow
      readonly
    ></v-textarea>

    <v-textarea
      v-show="provider_comment && provider_comment.length > 0"
      v-model="provider_comment"
      label="供應商備註"
      outlined
      name="provider_comment"
      auto-grow
      readonly
    ></v-textarea>

    <input
      v-for="(item, index) in data"
      :key="item.item_id"
      hidden
      :name="`items[${index}][item_id]`"
      :value="item.item_id"
    />
  </div>
</template>

<script lang="babel" type="text/babel">
import orderItemMixins from "@/mixins/orderItem.js";
export default {
  mixins: [orderItemMixins],
  components: {
    "store-order-card": () =>
      import("@/modules/base/components/priceCard/order/storeOrderCard.vue"),
    "block-title": () => import("@/modules/base/components/blockTitle.vue"),
    "number-bar": () => import("@/modules/base/components/bar/numberBar.vue"),
  },
  data: () => ({
    data: null,
    orderCardData: null,
    comment: null,
    provider_comment: null,
  }),
  computed: {
    status() {
      if (!this.propsValue) return "";
      return this.propsValue.status;
    },
    editAble() {
      return ["created", "apply"].includes(this.status);
    },
    backOrder() {
      if (!this.propsValue) return null;
      return this.propsValue.backOrder;
    },
    backOrderStatus() {
      if (!this.backOrder) return null;
      return this.backOrder.backOrder_status;
    },
    // secondary 黑, primary 淺
    itemNumberBarTheme() {
      if (this.backOrder) {
        if (this.backOrderStatus == "REJECT") return "secondary";
        return "primary";
      }
      return "secondary";
    },
    itemNumberBarTitle() {
      return "總計";
    },
    itemAmountTitle() {
      return "小計";
    },
    usePropsAmount() {
      return true;
    },
  },
  watch: {
    propsValue: {
      immediate: true,
      deep: true,
      handler() {
        if (!this.propsValue) return;
        this.data = this.$eagleLodash.cloneDeep(this.propsValue.items);
        this.comment = this.propsValue.comment;
        this.provider_comment = this.propsValue.provider_comment;
        this.propsAmount = this.propsValue.total_amounts;
        this.delivery_fee = Number(this.propsValue.delivery_fee).toFixed(0);
      },
    },
    data: {
      deep: true,
      async handler() {
        this.orderCardData = this.data.map((item) => {
          return {
            shipping_unit: item.shipping_unit, // 出貨單位
            calculate_unit: item.calculate_unit, // 計價單位
            per_shipping_count: item.per_shipping_count, //  ex: 規格:每籃5包
            shipping_count: Number(this.$helper.reverseShippingCount(
              item.shipping,
              item.shipping_unit
            )).toFixed(0), // 出貨數量
            origin_price: Number(item.origin_price).toFixed(0),
            price: this.editAble ? Number(this.getInitialPrice(item)).toFixed(0) : Number(item.price).toFixed(0), // 單價
            order_count: Number(this.$helper.reverseShippingCount(
              item.count,
              item.shipping_unit
            )).toFixed(0), // 訂購數量 / 數量
          };
        });
        // 一開始要等子元件渲染完
        await this.$helper.delay(0.5);
        await this.getChildrenTotal();
      },
    },
    orderCardData: {
      deep: true,
      async handler() {
        await this.getChildrenTotal();
      },
    },
  },
  methods: {
    cardConfigs(index) {
      const item = this.data[index];
      let configs = [
        { label: item.product, colon: false, class: "border-b" },
        { id: "order_count", label: "訂購數量", col: 6 },
        {
          id: "origin_price",
          label: "訂購單價",
          col: 6,
          name: `items[${index}][origin_price]`,
          valueSuffix: "元",
        },
        { id: "shipping_count", label: "出貨數量", col: 6 },
        {
          id: "price",
          label: "出貨單價",
          col: 6,
          name: `items[${index}][price]`,
          valueSuffix: "元",
        },
        { label: "", colon: false, col: 6 },
      ];

      // 出貨數量小於訂購數字 -> 缺貨
      if (item.shipping < item.count) {
        configs = [
          { label: item.product, colon: false, class: "border-b", col: 6 },
          { label: "缺貨", col: 6, colon: false, class: "border-b red--text" },
          { id: "order_count", label: "訂購數量", col: 6 },
          {
            id: "origin_price",
            label: "訂購單價",
            col: 6,
            name: `items[${index}][origin_price]`,
            valueSuffix: "元",
          },
          { id: "shipping_count", label: "出貨數量", col: 6 },
          {
            id: "price",
            label: "出貨單價",
            col: 6,
            name: `items[${index}][price]`,
            valueSuffix: "元",
          },
          { label: "", colon: false, col: 6 },
        ];
      }

      if (this.status != "canceled") {
        configs.push({
          id: "total",
          label: "小計",
          type: "total",
          barType: "primary",
          valueSuffix: "元",
          col: 6,
        });
      }

      return configs;
    },
  },
};
</script>
